import * as S from "./Banner.styles";
import { MdWhatsapp } from "react-icons/md";
import { HiOutlinePhone } from "react-icons/hi";
import { FiInstagram } from "react-icons/fi";
import bannerImage from "../../images/banner.webp";
import { Button } from "../../components/Button/Button";

export const Banner = () => {
  const isMobile = window.innerWidth < 760;
  return (
    <S.BannerWrapper $ismobile={isMobile.toString()}>
      <S.LeftContent>
        <S.BannerTitle>
          Aprenda a lidar
          <br /> com seus
          <br /> pensamentos.
        </S.BannerTitle>
        <S.BannerSubTitle>Áurea Gomes | Psicóloga Clínica</S.BannerSubTitle>
        <S.BannerBody>
          Aqui você vai encontrar um trabalho <strong>especializado</strong> e
          com <strong>evidências</strong> científicas de sua efetividade para
          diminuir o seu sofrimento mental e trazer <strong>leveza</strong> para
          sua vida.
          <br />
          <br />
          Entre em contato para saber mais sobre os atendimentos e agende sua
          consulta agora.
        </S.BannerBody>
        <Button icon={<MdWhatsapp size={28} />} />
        <S.PhoneWrapper>
          <HiOutlinePhone />
          <S.BannerBody>(82)993416300</S.BannerBody>
        </S.PhoneWrapper>
        <S.PhoneWrapper>
          <FiInstagram />
          <S.BannerBody>psi.aureagomes</S.BannerBody>
        </S.PhoneWrapper>
      </S.LeftContent>
      <S.BannerImage
        src={bannerImage}
        alt="Aurea Banner"
        className="imageBanner"
      />
    </S.BannerWrapper>
  );
};
